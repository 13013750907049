import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/veterans.scss"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SmileForVeteransPage = () => {
  return (
    <Layout language="en">
      <SEO
        title="Smiles For Veterans Program"
        description="Smiles For Veterans Program at The Regional Center for Dental Implants & Oral Surgery"
      />
      <div className="sfv">
        <div className="sfv__hero sfv__section">
          <img
            className="sfv__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-desktop-hero-image"
            alt="smiles for soliders banner"
          />
          <img
            className="sfv__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-mobile-hero-image"
            alt="smiles for soliders banner"
          />
        </div>

        <div className="sfv__body sfv__section">
          <div className="sfv__container small">
            <h1>SUPPORTING THOSE WHO SERVE</h1>

            <p>
              The Regional Center for Dental Implants & Oral Surgery is proud to
              launch our Smiles For Veterans program. This program provides one
              veteran with a complimentary{" "}
              <Link
                to="/procedures/full-arch-restoration-johnson-city-tn/"
                title="Learn more about full-arch-restoration">
                full-arch restoration
              </Link>{" "}
              procedure.
            </p>
            <h2 style={{ fontSize: "24px" }}>FULL-ARCH RESTORATION</h2>
            <p>
              The recipient will receive a complete set of new teeth at no cost.
              This type of tooth replacement is called full-arch restoration,
              and it involves using dental implants to secure a fixed denture in
              one or both arches. Our experts will work closely with a
              restorative dentist to complete the treatment.{" "}
            </p>
            <p>
              If you are a retired or active duty military member who has
              difficulty eating, smiling, or speaking because of missing or
              failing teeth, this could be a life-changing opportunity.{" "}
            </p>
          </div>
        </div>

        <div className="sfv__section">
          <div className="sfv__container">
            <div className="sfv__brackets">
              <div className="sfv__about-grid">
                <div>
                  <p className="sfv__subheading">How To Apply</p>
                  <ul>
                    <li>
                      Be an active duty, reserve, or veteran of the military
                    </li>
                    <li>Answer a few simple questions</li>
                    <li>
                      Submit digital photos of your current smile and your teeth
                    </li>
                    <li>Submit your application by October 31st, 2024</li>
                  </ul>
                </div>
              </div>
            </div>

            <a
              className="sfv__submit-btn"
              href="https://secureform.seamlessdocs.com/f/nnt81i2orlu0"
              title="Follow the link to submit your application"
              target="_blank">
              SUBMIT APPLICATION
            </a>
          </div>
        </div>

        {/* <div className="sfv__section">
          <div className="sfv__container">
            <ImageSlider
              adaptiveHeight
              useDots
              images={[
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-1",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-2",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-3",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-4"
              ]}
            />
          </div>
        </div> */}

        <div className="sfv__section">
          <div className="sfv__container small">
            <p>
              Follow this page,{" "}
              <a
                href="https://www.facebook.com/regionalcenterforoms/"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/regionalcenterforoms/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              for more program updates!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/procedures/dental-implants-johnson-city-tn/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/procedures/full-arch-restoration-johnson-city-tn/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can improve your oral health and confidence.
                Whether you need to replace one tooth or an entire arch of
                teeth, our team has a solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileForVeteransPage
